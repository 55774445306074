import { Category, CustomizedCategoryItem, Filter, FilterOperator } from '../data/categories';
import { DirectoryUser } from '../data/data.models';
import { DirectoryUserModification, DirectoryUserModificationStatus } from '../integrity/integrity.models';

//export a static function
export function arrangeCategoryItemsByCategory(categoryItems: CustomizedCategoryItem[], categories: Category[]) {
    const categoriesFromItems = categoryItems.reduce(
        (acc, item) => {
            item = { ...item, columnValues: { ...item.columnValues } };
            const category = categories.find((c) => c.fieldName === item.fieldName);
            if (category) {
                if (category.id !== item.categoryId) {
                    item.categoryId = category.id;
                }
            } else {
                item.categoryId = -1;
            }
            if (!acc[item.categoryId]) {
                acc[item.categoryId] = [];
            }
            acc[item.categoryId].push(item);
            return acc;
        },
        {} as Record<number, CustomizedCategoryItem[]>
    );
    return categoriesFromItems;
}

//reverse function to flatten into array
export function flattenCategoryItemsByCategory(categoryItemsByCategory: Record<number, CustomizedCategoryItem[]>) {
    const categoryItems = Object.keys(categoryItemsByCategory).reduce(
        (acc, key) => acc.concat(categoryItemsByCategory[key]),
        [] as CustomizedCategoryItem[]
    );
    return categoryItems;
}

export function modifyAndFilterUsers(
    users: DirectoryUser[],
    filters: Filter[],
    userModifications: DirectoryUserModification[]
): DirectoryUser[] {
    //console.log('unfiltered length', users.length);
    const modified = users.map((user) => {
        const modifications = userModifications.filter(
            (mod) =>
                mod.userId === user.id &&
                (mod.status === DirectoryUserModificationStatus.Approved ||
                    mod.status === DirectoryUserModificationStatus.Applied)
        );
        if (modifications) {
            const modifiedUser = {
                ...user,
                ...modifications.reduce((acc, mod) => ({ ...acc, [mod.fieldName as string]: mod.newValue }), {})
            };
            return modifiedUser;
        }
        return user;
    });

    const filtered =
        !filters || filters.length === 0
            ? modified
            : modified.filter((user) => {
                  return !filters
                      .filter((x) => x.isEnabled)
                      .some((filter) => {
                          //apply filter complexConditions as OR and inner conditions as AND
                          return filter.complexConditions.some((complexCondition) => {
                              return complexCondition.every((condition) => {
                                  const userValue =
                                      user[condition.fieldName] == null
                                          ? ''
                                          : user[condition.fieldName].toString().toLowerCase();

                                  if (condition.operator === FilterOperator.Contains) {
                                      //check all condition.values
                                      return condition.values.some((value) => {
                                          return userValue.indexOf(value.toLowerCase()) !== -1;
                                      });
                                  }
                                  if (condition.operator === FilterOperator.DoesNotContain) {
                                      //check all condition.values
                                      return condition.values.every((value) => {
                                          return userValue.indexOf(value.toLowerCase()) === -1;
                                      });
                                  }
                                  if (condition.operator === FilterOperator.StartsWith) {
                                      //check all condition.values
                                      return condition.values.some((value) => {
                                          return userValue.startsWith(value.toLowerCase());
                                      });
                                  }
                                  if (condition.operator === FilterOperator.EndsWith) {
                                      //check all condition.values
                                      return condition.values.some((value) => {
                                          return userValue.endsWith(value.toLowerCase());
                                      });
                                  }
                                  if (condition.operator === FilterOperator.Equals) {
                                      //check all condition.values
                                      return condition.values.some((value) => {
                                          return userValue === value.toLowerCase();
                                      });
                                  }
                                  if (condition.operator === FilterOperator.DoesNotEqual) {
                                      //check all condition.values
                                      return !condition.values.some((value) => {
                                          return userValue !== value.toLowerCase();
                                      });
                                  }
                                  return false;
                              });
                          });
                      });
              });

    return filtered;
}
