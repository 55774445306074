import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardAuthRequest, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { select, Store } from '@ngrx/store';
import { catchError, EMPTY, Observable, Subject, take, takeUntil, throwError, withLatestFrom } from 'rxjs';
import { BrowserService } from '../../../services/browser-service';
import { FeatureService } from '../../services/feature.service';
import { Feature, FeatureDefinition, FeatureDefinitions } from './features.models';
import * as fromRoot from '../../../reducers';
import * as AuthActions from '../../../auth/auth.actions';
import * as microsoftTeams from '@microsoft/teams-js';
import * as dataActions from '../../../data/data.actions';
import { ModifiedAccountInfo } from 'src/app/auth/auth.models';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'dir-feature-consent',
    templateUrl: './feature-consent.component.html',
    styleUrls: ['./feature-consent.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [style({ height: 0 }), animate('200ms ease-in')]),
            transition(':leave', [animate('200ms ease-in', style({ height: 0 }))])
        ])
    ],
    imports: [NgIf, NgFor]
})
export class FeatureConsentComponent implements OnInit, OnDestroy {
    @Input() name: Feature = Feature.Directory;
    @Output() featureConsentSuccess = new EventEmitter<Feature>();
    isError = false;
    errorMessage = '';
    private unsubscribe$ = new Subject<void>();
    user$: Observable<ModifiedAccountInfo>;
    isTeams$: Observable<boolean>;
    isOnline = true;
    feature: FeatureDefinition;
    featureConsented = false;
    currentScopes: string[] = [];
    userScopes$: Observable<string[]>;

    constructor(
        private store: Store,
        private browserService: BrowserService,
        private featureService: FeatureService,
        private msalService: MsalService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
    ) {}

    ngOnInit(): void {
        this.featureService.availableScopes$.pipe(takeUntil(this.unsubscribe$)).subscribe((scopes) => {
            if (this.feature) {
                this.featureConsented = this.feature.scopes.every((scope) =>
                    scopes.map((scope) => scope.toLowerCase()).includes(scope.toLowerCase())
                );
            }
        });
        this.browserService.isOnline$.pipe(takeUntil(this.unsubscribe$)).subscribe((online) => {
            this.isOnline = online;
        });
        this.feature = FeatureDefinitions[this.name];

        this.featureService
            .hasAllScopes(this.feature.scopes)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((hasScope) => {
                this.featureConsented = hasScope;
            });

        this.featureService
            .getUserScopes()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((scopes) => {
                this.currentScopes = scopes;
            });

        this.user$ = this.store.pipe(select(fromRoot.selectUser));

        this.isTeams$ = this.store.pipe(select(fromRoot.selectIsTeams));

        this.userScopes$ = this.featureService.getUserScopes();
        this.featureService.refreshAvailableScopes();
    }

    onUserConsent(): void {
        this.errorMessage = '';
        this.isError = false;
        this.user$
            .pipe(takeUntil(this.unsubscribe$), withLatestFrom(this.isTeams$), take(1))
            .subscribe(([user, isTeams]) => {
                if (user) {
                    if (isTeams) {
                        this.consentTeams(user);
                    } else {
                        this.consentBrowser(user);
                    }
                } else {
                    console.error('user is null');
                }
            });
    }

    private consentBrowser(user: ModifiedAccountInfo) {
        this.msalService
            .acquireTokenPopup({
                scopes: [...this.feature.scopes],
                loginHint: user.username,
                state: 'AddScopes',
                domainHint: user.tenantId
            })
            .pipe(
                take(1),
                catchError((error) => {
                    if (error) {
                        if (error.toString() == 'BrowserAuthError: user_cancelled: User cancelled the flow.') {
                            this.cancelledConsentMessage();
                            return EMPTY;
                        }
                    }
                    return throwError(() => new Error(error));
                })
            )
            .subscribe((result) => {
                if (result.accessToken) {
                    if (
                        result.scopes &&
                        result.scopes.length > 0 &&
                        this.feature.scopes.every((scope) =>
                            result.scopes.some((s) => s.toLowerCase() === scope.toLowerCase())
                        )
                    ) {
                        this.postConsentSuccess();
                    } else {
                        this.postConsentFail();
                    }
                    this.featureService.refreshAvailableScopes();
                }
            });
    }

    private consentTeams(user: ModifiedAccountInfo) {
        const request: MsalGuardAuthRequest = { ...this.msalGuardConfig.authRequest };
        request.scopes = [...new Set([...request.scopes, ...this.feature.scopes])];
        microsoftTeams.app.initialize().then(() => {
            console.log('teams api initialized in feature-consent.component.ts');
            this.store.dispatch(AuthActions.startTeamsInteraction());
            microsoftTeams.authentication
                .authenticate({
                    url: window.location.origin + '/auth-teams-scopes?scopes=' + request.scopes.join(','),
                    height: 640
                })
                .then(
                    (result) => {
                        console.log('success, reloading');
                        this.store.dispatch(AuthActions.endTeamsInteraction());
                        this.featureService
                            .refreshAvailableScopesAsObservable()
                            .pipe(take(1))
                            .subscribe((scopes) => {
                                this.featureService.availableFeatures$.pipe(take(1)).subscribe((features) => {
                                    if (features.includes(this.name)) {
                                        this.postConsentSuccess();
                                    } else {
                                        this.postConsentFail();
                                    }
                                });
                            });
                    },
                    (reason) => {
                        console.log('INTERACTION FAILED IN APP.COMPONENT.TS');
                        console.log('failure', reason);
                        console.log(JSON.stringify(reason));
                        this.store.dispatch(AuthActions.endTeamsInteraction());
                        if (reason.message === 'consent_required' || reason.message === 'CancelledByUser') {
                            this.cancelledConsentMessage();
                            this.featureService.refreshAvailableScopes();
                        } else {
                            this.isError = true;
                            this.errorMessage = 'Error Providing Consent: ' + reason.message;
                        }
                    }
                );
        });
    }

    cancelledConsentMessage() {
        this.isError = true;
        this.errorMessage = 'Consent is required to use this feature.';
    }

    postConsentFail() {
        console.log("You don't have the feature - we need to do something about that");
        this.isError = true;
        this.errorMessage = 'Could not get consent this time.';
    }

    postConsentSuccess() {
        console.log('Well done - you have consented to the feature');
        this.isError = false;
        this.errorMessage = '';
        this.store.dispatch(AuthActions.setIsDemo({ isDemo: false }));
        this.store.dispatch(dataActions.reloadUsersAfterConsent());
        this.featureConsentSuccess.emit(this.name);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
